import React, { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Layout from '../components/layout/LayoutInner';
import Seo from '../components/layout/SEO';
import InnerHead from '../components/layout/InnerHead';

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue)
  const handleChange = e => {
    const thisValue = e.target.value === 'checkbox' ? e.target.checked : e.target.value;
    setValue(thisValue)
  }
  return {
    value,
    onChange: handleChange,
  }
}

export default function Form() {
  const name = useFormInput('')
  const email = useFormInput('')
  const message = useFormInput('')
  const fruit = useFormInput('')
  const acknowledge = useFormInput('')
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [notification, setNotification] = useState('')

  // this will be important later - to stringify data
  const nameVal = name.value
  const emailVal = email.value
  const messageVal = message.value
  const fruitVal = fruit.value
  const acknowledgeVal = acknowledge.value

  const handleSubmit = async (e) => {
    e.preventDefault()
    //  Check if recaptcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    // form field validations
    if (!name.value) {
      setNotification(`Please don't leave any of the fields empty.`);
    } else if (!email.value) {
      setNotification(`Please don't leave any of the fields empty.`);
    } else if (!message.value) {
      setNotification(`Please don't leave any of the fields empty.`);
    } else if (!fruit.value) {
      setNotification(`Please make a selection.`);
    } else if (!acknowledge.checked) {
      setNotification(`Please acknowledge.`);
    }
    const token = await executeRecaptcha('homepage')

    const data = JSON.stringify({
      nameVal,
      emailVal,
      messageVal,
      fruitVal,
      acknowledgeVal,
      token
    })

    fetch('http://localhost:3000/api/v1/contact/test', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-type': 'application/json'
      },
      body: data
    })
      .then(res => res.json())
      .then(data => {
        setNotification(data.msg)
      })

    setNotification('Data Sent')
  }

  return (
    <Layout>
      <Seo
        title='Test Form'
        canonical='contact-us/'
      />
      <InnerHead title="Test Form" caption="Dreyer Law" />
      <main className="contact-page">
        <div className="container">
          <div role="form" className='contactform--form'>
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" {...name} required />
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" {...email} required />
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" placeholder="Your message" {...message} required></textarea>
              <label htmlFor="fruit">Favorite Flavor</label>
              <select name="fruit" id="fruit" {...fruit}>
                <option value="">Please select</option>
                <option value="grapefruit">Grapefruit</option>
                <option value="papaya">Papaya</option>
                <option value="dragonfruit">Dragonfruit</option>
              </select>
              <label htmlFor="acknowledge">I acknowledge you rock!</label>
              <input type="checkbox" name="acknowledge" id="acknowledge" {...acknowledge} required />
              <input type="submit" value="Send" />
              {notification && <span>{notification}</span>}
            </form>
          </div>
        </div>
      </main>
    </Layout>

  )
}